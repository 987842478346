import React from 'react'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import MayasLogo from '../../images/logo-yellow.svg'

const Logo = styled.img`
  width: 100px;
  padding: 10px;
  vertical-align: middle;
  opacity: 1;
  transition: all 0.4s;
  &:hover {
    opacity: 0.6;
    filter: grayscale(100%);
  }
`

const Wrapper = styled.footer`
  background: ${props => props.theme.colors.base};
`

const Container = styled.div`
  color: white;
  font-size: 0.9em;
  max-width: ${props => props.theme.sizes.maxWidth};
  width: 100%;
  padding: 2rem 1.5rem;
  margin: 0 auto;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    padding: 0 1.5rem;
    padding: 3rem 1.5rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }
`

const Copyright = styled.p`
  display: flex;
  align-items: center;
  lex-flow: row wrap;
  opacity: 0.5;
  margin: 0 0 1rem 0;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 0;
  }
`
const Legalnotice = styled.div`
  margin: 0 0 0.5rem 0;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 0 0 0 0;
  }
  a {
    transition: 0.3s color;
    color: white;
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    @media (hover: none) {
      color: white !important;
    }
  }
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    justify-content: space-between;
    align-items: center;
  }
`

const Item = styled.li`
  margin: 0 0.5rem 0.5rem 0;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 0 0 0 0.5rem;
  }
  a {
    transition: 0.3s color;
    color: white;
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    @media (hover: none) {
      color: white !important;
    }
  }
`

const Footer = props => {
  //const { author, footerLinks } = useSiteMetadata()
  const { footerLinks } = useSiteMetadata()
  
  //{author}
  
  return (
    <Wrapper>
      <Container>
        <Copyright>
          <Logo src={MayasLogo} alt="Logo" />
          &copy; {new Date().getFullYear()} 
        </Copyright>
        <Legalnotice>
          <a href="https://mayashundesalon.de/legalnotice/" target="_blank" rel="noopener noreferrer">Impressum</a>
          { ' | ' }
          <a href="https://mayashundesalon.de/privacy/" target="_blank" rel="noopener noreferrer">Datenschutz</a>
        </Legalnotice>
        {footerLinks && (
          <List>
            {footerLinks.map(link => (
              <Item key={link.name}>
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.name}
                </a>
              </Item>
            ))}
          </List>
        )}
      </Container>
    </Wrapper>
  )
}

export default Footer
